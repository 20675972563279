import React ,{useState} from "react";
import './App.css';
import { Chart } from "react-google-charts";

var questions = [

  {
    questionText: 'Sua empresa possui um Planejamento Estratégico?', 
    answerOptions:[
    {answerText:'SIM', isCorrect:12},
    {answerText:'PARCIAL', isCorrect:6},
    {answerText:'NAO', isCorrect:0}  
  ], },

  {
    questionText: 'Realizam Analise Swot de sua empresa?', 
    answerOptions:[
    {answerText:'SIM', isCorrect:8},
    {answerText:'PARCIAL', isCorrect:4},
    {answerText:'NAO', isCorrect:0}
  ], },

  {
    questionText: 'Sua empresa possui Objetivos Estratégicos bem definidos?', 
    answerOptions:[
    {answerText:'SIM', isCorrect:10},
    {answerText:'PARCIAL', isCorrect:5},
    {answerText:'NAO', isCorrect:0}
  ], },

  
    {
      questionText: 'Seus Objetivos são desmembrados em indicadores de desempenho?', 
      answerOptions:[
      {answerText:'SIM', isCorrect:10},
      {answerText:'PARCIAL', isCorrect:5},
      {answerText:'NAO', isCorrect:0}
  ], },

  {
    questionText: 'Os Indicadores de Desempenho são monitorados diariamente?', 
    answerOptions:[
    {answerText:'SIM', isCorrect:8},
    {answerText:'PARCIAL', isCorrect:4},
    {answerText:'NAO', isCorrect:0}
  ], },

  {
    questionText: 'Os indicadores possuem responsáveis por seu monitoramento?', 
    answerOptions:[
    {answerText:'SIM', isCorrect:10},
    {answerText:'PARCIAL', isCorrect:5},
    {answerText:'NAO', isCorrect:0}
  ], },

  {
    questionText: 'Possuem controle de Planos de Ações sobre os Indicadores?', 
    answerOptions:[
    {answerText:'SIM', isCorrect:10},
    {answerText:'PARCIAL', isCorrect:5},
    {answerText:'NAO', isCorrect:0}
  ], },

  {
    questionText: 'Os Planos de Ações são monitorados quanto sua efetividade e prazos?', 
    answerOptions:[
    {answerText:'SIM', isCorrect:6},
    {answerText:'PARCIAL', isCorrect:3},
    {answerText:'NAO', isCorrect:0}
  ], },

  {
    questionText: 'Possuem rotina de Apresentação dos Resultados a Direção?', 
    answerOptions:[
    {answerText:'SIM', isCorrect:6},
    {answerText:'PARCIAL', isCorrect:3},
    {answerText:'NAO', isCorrect:0}
  ], },

  {
    questionText: 'As Ações propostas resultam em mudanças de resultados?', 
    answerOptions:[
    {answerText:'SIM', isCorrect:8},
    {answerText:'PARCIAL', isCorrect:4},
    {answerText:'NAO', isCorrect:0}
  ], },

  {
    questionText: 'Sua Empresa alcança os Resultados Esperados?', 
    answerOptions:[
    {answerText:'SIM', isCorrect:12},
    {answerText:'PARCIAL', isCorrect:6},
    {answerText:'NAO', isCorrect:0}
  ], },
  
];

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function postar (vetor) {

  var respostas = { "pergunta_um" : "'Sua empresa possui um Planejamento Estratégico?'",     
	"resposta_um" : vetor[0] ,
      "pergunta_dois" : "'Realizam Analise Swot de sua empresa?'" ,
      "resposta_dois" : vetor[1] ,
      "pergunta_tres" :"'Sua empresa possui Objetivos Estratégicos bem definidos?'",
      "resposta_tres" : vetor[2] ,
      "pergunta_quatro" :"'Seus Objetivos são desmembrados em indicadores de desempenho?'",
      "resposta_quatro" : vetor[3],
      "pergunta_cinco" :"'Os Indicadores de Desempenho são monitorados diariamente?'",
      "resposta_cinco" : vetor[4], 
      "pergunta_seis" :"'Os indicadores possuem responsáveis por seu monitoramento?'",
 			"resposta_seis" : vetor[5],
      "pergunta_sete" :"'Possuem controle de Planos de Ações sobre os Indicadores?'",
      "resposta_sete" : vetor[6],
      "pergunta_oito" :"'Os Planos de Ações são monitorados quanto sua efetividade e prazos?'",
      "resposta_oito" : vetor[7],
      "pergunta_nove" :"'Possuem rotina de Apresentação dos Resultados a Direção?'",
      "resposta_nove" : vetor[8],
      "pergunta_dez" :"'As Ações propostas resultam em mudanças de resultados?'",
      "resposta_dez" : vetor[9],
      "pergunta_onze" :"'Sua Empresa alcança os Resultados Esperados?'",
      "resposta_onze" : vetor[10] };

    console.log('teste')

    fetch('http://localhost:3001/horus', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(respostas) 
  })
}

function App() {
  var {height, width} = getWindowDimensions();

  const [showScore, setShowScore] = useState(0);
  const [score, setScore] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  var [vetor,setVetor] = useState([]);

  const data = [
    ["Pontuação", "Ponto"],
    ["Alerta", 50],
    ["Preucupante", 70],
    ["Minimo Ideal", 90],
    ["Sua Pontuação", score],
  ];
  
  const options = {
    chart: {
      title: "Pontuação",
      subtitle: "Pontuação baseada nas respostas",
    },
    hAxis: {
      title: "Pontuação Toal",
      minValue: 0,
    },
    vAxis: {
      title: "Tipo",
    },
    backgroundColor: '#E4E4E4',
    bars: "horizontal",
    axes: {
      y: {
        0: { side: "right" },
      },
    },
  };


  function vetorizar(valor,vet){
    vet.push(valor);
    setVetor(vet);
  }

  function handleAnswer(valor) {
  
      setScore(score + valor);

    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < questions.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      setShowScore(1);
    }
  }

  function resultado(score){
    const mensagen = ['OPS!! VERIFICAMOS QUE MESMO OBTENDO UM BAIXO INDICE DE MATURIDADE DE SUA GESTÃO POR RESULTADOS, SUA EMPRESA VEM ATINGINDO OS RESULTADOS ESPERADOS;',
    'PARABÉNS !!! SUA EMPRESA VEM ATINGINDO OS RESULTADOSESPERADOS, PORÉM FIQUE ATENTO POIS ISSO VEM OCORRENDO MESMO QUE O NÍVEL DE MATURIDADE DE GESTÃO POR RESULTADOS NÃO ESTEJAM ENQUADRADOS A NÍVES SATISFATÓRIOS, ISSO PODERÁ A LONGO PRAZO COMPROMETER A CONTINUIDADE DESSA PERFORMANCE;',
    'PARABÉNS !!! SUA EMPRESA VEM ATINGINDO OS RESULTADOS ESPERADOS E ESTÁ COM UM BELO NÍVEL DE MATURIDADE DO PROCESSO DE GESTÃO POR RESULTADOS;'];

    if(score <= 65){
      return mensagen[0];
    }
    else if(score <= 84){
      return mensagen[1];
    }
    else if(score >= 85){
      return mensagen[2];
    }
  }


  return (
    <div className="app">
    {showScore ? ( <div>
      <text>{resultado(score)}</text>
      <Chart
    chartType="Bar"
    width="100%"
    height="400px"
    data={data}
    options={options}
  />
  {postar(vetor)}
  </div>
    ) : (
        <>
          <div className="question-section">
            <div className="question-count">
              <span>Questão {currentQuestion + 1}</span>/{questions.length}
            </div>
            <div className="question-text">
              {questions[currentQuestion].questionText}
            </div>
          </div>

          <div className="answer-section">
            {questions[currentQuestion].answerOptions.map(
              (answerOption, index) => (
                <button
                  onClick={() => [handleAnswer(answerOption.isCorrect) ,vetorizar(answerOption.isCorrect,vetor)]}
                  key={index}
                >
                  {answerOption.answerText}
                </button>
              )
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default App;
